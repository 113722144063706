<template>
  <div class="content">
    <div class="row">
      <div class="col-12">
        <card>
          <div class="ml-1">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">Home</li>
                <li class="breadcrumb-item active" aria-current="page">
                  {{ $t("liquidation.title") }}
                </li>
              </ol>
            </nav>
          </div>
          <div class="row ml-3 mb-3">
            <b-col cols="6">
              <h6 class="ssp-24">{{ $t("liquidation.title") }}</h6>
            </b-col>
          </div>
          <div class="row table-utilities">
            <div class="row ml-2 w-100">
              <div class="col-md-10 search-section">
                <div class="input-group">
                  <div class="input-group-prepend bg-white">
                    <span class="input-group-text" id="basic-addon1">
                      <div class="font-12">
                        <i class="ri-search-line ri-lg ri-sub"></i>
                      </div>
                    </span>
                  </div>
                  <input
                    type="text"
                    class="form-control search-input"
                    :placeholder="$t('liquidation.search')"
                    @input="setPage"
                    v-model="filterInput"
                  />
                </div>
              </div>
              <div class="col-md-2 pl-0">
                <button
                  class="btn table-button ssp-14-700 export-button h-100 w-100 d-flex align-items-center"
                  @click="handleExport"
                  :disabled="isLoading"
                >
                  <div class="font-12">
                    <div
                      v-if="isLoading"
                      class="spinner-border spinner-border-sm text-success mr-1"
                    >
                      <span class="sr-only">Loading...</span>
                    </div>
                    <i v-else class="ri-download-line ri-lg ri-mid mr-1"></i>
                  </div>
                  {{ $t("global.export") }}
                </button>
              </div>
            </div>
            <div class="row w-100 ml-2 mt-2">
              <div class="col-md-2 pl-0">
                <b-button
                  class="btn table-button ssp-14-700 h-100 d-flex align-items-center"
                  id="popover-button-variant"
                  href="#"
                  tabindex="0"
                >
                  <div class="font-12">
                    <i class="ri-filter-2-line ri-lg ri-mid mr-1"></i>
                  </div>
                  {{ $t("global.filter") }}
                </b-button>
              </div>
              <b-popover
                placement="right"
                target="popover-button-variant"
                triggers="focus"
              >
                <b-tabs content-class="mt-2">
                  <b-tab title="Tanggal" active>
                    <div class="form-check multiple-row">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="exampleRadios"
                        id="radio-today"
                        :value="filterDateTypes[0]"
                        @change="resetFilter1"
                        checked
                        v-model="filterDateType"
                      />
                      <label class="form-check-label" for="radio-today">
                        <div class="col">
                          <div class="row ssp-14">Today</div>
                          <div class="row">{{ today | formatDate2 }}</div>
                        </div>
                      </label>
                    </div>
                    <div class="form-check multiple-row">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="exampleRadios"
                        id="radio-yesterday"
                        :value="filterDateTypes[1]"
                        @change="resetFilter1"
                        v-model="filterDateType"
                      />
                      <label class="form-check-label" for="radio-yesterday">
                        <div class="col">
                          <div class="row ssp-14">Yesterday</div>
                          <div class="row">{{ yesterday | formatDate2 }}</div>
                        </div>
                      </label>
                    </div>
                    <div class="form-check multiple-row">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="exampleRadios"
                        id="radio-week"
                        :value="filterDateTypes[2]"
                        @change="setFilterSelectedWeek"
                        v-model="filterDateType"
                      />
                      <label class="form-check-label" for="radio-week">
                        <div class="col">
                          <div class="row ssp-14">This week</div>
                          <div class="row">
                            <span>{{
                              `${getDay(firstDayWeek)}&nbsp;-&nbsp;`
                            }}</span>
                            <span>{{ lastDayWeek | formatDate2 }}</span>
                          </div>
                        </div>
                      </label>
                    </div>
                    <div class="form-check multiple-row">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="exampleRadios"
                        id="radio-month"
                        :value="filterDateTypes[3]"
                        @change="setFilterSelectedMonth"
                        v-model="filterDateType"
                      />
                      <label class="form-check-label" for="radio-month">
                        <div class="col">
                          <div class="row ssp-14">This month</div>
                          <div class="row">
                            <span>{{
                              `${getDay(firstDayMonth)}&nbsp;-&nbsp;`
                            }}</span>
                            <span>{{ lastDayMonth | formatDate2 }}</span>
                          </div>
                        </div>
                      </label>
                    </div>
                    <div class="form-check mb-2">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="exampleRadios"
                        id="radio-custom"
                        :value="filterDateTypes[4]"
                        v-model="filterDateType"
                      />
                      <label class="form-check-label ssp-14" for="radio-custom">
                        Custom Range
                      </label>
                    </div>
                    <b-row
                      v-if="filterDateType == filterDateTypes[4]"
                      class="mb-2"
                    >
                      <b-col cols="6" class="pr-1">
                        <span>FROM</span>
                        <input
                          type="date"
                          class="form-control float-right"
                          v-model="filterSelected1"
                        />
                      </b-col>
                      <b-col cols="6" class="pl-1">
                        <span>TO</span>
                        <input
                          type="date"
                          class="form-control"
                          v-model="filterSelected2"
                        />
                      </b-col>
                    </b-row>
                    <button
                      @click="resetFilter"
                      type="string"
                      class="btn btn-success w-100 ssp-14"
                      :class="{ disabled: filterDateType === null }"
                    >
                      {{ $t("filter.reset") }}
                    </button>
                  </b-tab>
                  <b-tab title="User">
                    <div class="row">
                      <select
                        class="form-control search-input-1 col-5 ml-3 mr-1"
                        v-model="filterRole"
                      >
                        <option
                          v-for="role in roleOptions"
                          :key="role.id"
                          :value="role"
                        >
                          {{ role }}
                        </option>
                      </select>
                      <input
                        type="text"
                        class="form-control search-input-1 col-5 ml-2 mr-1"
                        v-model="searchUser"
                      />
                    </div>
                    <hr class="m-2" />
                    <div class="filter-overflow-srch">
                      <div
                        class="form-checkbox"
                        v-for="user in dataUser"
                        :key="user.id"
                      >
                        <input
                          class="form-checkbox-item"
                          type="checkbox"
                          name="kegiatan-label"
                          :id="user.id"
                          :value="user.id"
                          v-model="filterRoleApply"
                        />
                        <label
                          class="form-checkbox-label ssp-14-400"
                          :for="user.id"
                        >
                          {{ user.name }}
                        </label>
                      </div>
                    </div>
                    <button
                      @click="resetFilter"
                      type="string"
                      class="btn btn-success w-100 mt-2 ssp-14"
                      :class="{ disabled: filterRoleApply == '' }"
                    >
                      {{ $t("filter.reset") }}
                    </button>
                  </b-tab>
                  <b-tab title="Region">
                    <div class="filter-overflow-srch">
                      <div
                        class="form-checkbox"
                        v-for="rg in dataRegion"
                        :key="rg.id"
                      >
                        <!-- <input
                          class="form-radio-item"
                          type="radio"
                          name="kegiatan-label"
                          :value="rg.id"
                          :id="rg.id"
                          v-model="filterRegion"
                        />
                        <label class="form-radio-label ssp-14-400" :for="rg.id">
                          {{ rg.region }}
                        </label> -->
                        <input
                          class="form-checkbox-item"
                          type="checkbox"
                          name="kegiatan-label"
                          :value="parseInt(rg.id)"
                          :id="rg.id"
                          v-model="filterRegion"
                        />
                        <label
                          class="form-checkbox-label ssp-14-400"
                          :for="rg.id"
                        >
                          {{ rg.region }}
                        </label>
                      </div>
                    </div>
                    <button
                      @click="resetFilter"
                      type="string"
                      class="btn btn-success w-100 mt-2 ssp-14"
                      :class="{ disabled: filterRegion.length === 0 }"
                    >
                      {{ $t("filter.reset") }}
                    </button>
                  </b-tab>
                  <b-tab title="Produk">
                    <div class="input-group">
                      <div class="input-group-prepend bg-white">
                        <span class="input-group-text" id="basic-addon1">
                          <div class="font-12">
                            <i class="ri-search-line ri-lg ri-sub"></i>
                          </div>
                        </span>
                      </div>
                      <input
                        type="text"
                        class="form-control search-input"
                        v-model="searchProduk"
                      />
                    </div>
                    <hr class="m-2" />
                    <div class="filter-overflow-srch">
                      <div
                        class="form-checkbox"
                        v-for="prod in dataProduk"
                        :key="prod.id"
                      >
                        <input
                          class="form-checkbox-item"
                          type="checkbox"
                          name="kegiatan-label"
                          :id="prod.id"
                          :value="prod.id"
                          v-model="filterProduk"
                        />
                        <label
                          class="form-checkbox-label ssp-14-400"
                          :for="prod.id"
                        >
                          {{ prod.brand }}
                        </label>
                      </div>
                    </div>
                    <button
                      @click="resetFilter"
                      type="string"
                      class="btn btn-success w-100 mt-2 ssp-14"
                      :class="{ disabled: filterProduk == '' }"
                    >
                      {{ $t("filter.reset") }}
                    </button>
                  </b-tab>
                </b-tabs>
              </b-popover>
            </div>
          </div>
          <div class="table-borderless mx-3">
            <b-table-simple hover responsive>
              <b-thead class="bv-head">
                <b-tr>
                  <b-th
                    ><input
                      type="checkbox"
                      @click="selectAll"
                      v-model="allSelected"
                  /></b-th>
                  <b-th v-for="hd in $t('liquidation.headers')" :key="hd.id">
                    {{ hd }}
                  </b-th>
                </b-tr>
              </b-thead>
              <b-tbody v-if="loading">
                <table-spinner cs="8" />
              </b-tbody>
              <!-- <b-tbody> -->
              <b-tbody v-else-if="this.dataLaporan.length !== 0">
                <b-tr
                  class="ssp-16-400"
                  v-for="laporan in dataLaporan"
                  :key="laporan.id"
                >
                  <b-td
                    ><input
                      type="checkbox"
                      :value="laporan.id"
                      v-model="lapIDs"
                      @click="select"
                  /></b-td>
                  <b-td>
                    <span>
                      {{ laporan.froms ? laporan.froms.name : "" }}
                    </span>
                    <span class="badge badge-gray">
                      {{ laporan.froms ? laporan.froms.role : "" }}
                    </span>
                  </b-td>
                  <b-td>{{ laporan.day_report | formatDate2 }}</b-td>
                  <b-td>{{ laporan.produknames.brand }}</b-td>
                  <b-td>{{ laporan.price | formatRp }}</b-td>
                  <b-td>{{ laporan.quantity }}</b-td>
                  <b-td>{{ laporan.value | formatRp }}</b-td>
                  <!-- <b-td>
                    <span v-if="laporan.retailers2 != null">{{
                      laporan.retailers2.name
                    }}</span>
                    <span v-else> - </span>
                  </b-td>
                  <b-td>
                    <span v-if="laporan.retailers1 != null">{{
                      laporan.retailers1.name
                    }}</span>
                    <span v-else> - </span>
                  </b-td>
                  <b-td>
                    <span v-if="laporan.distributors != null">{{
                      laporan.distributors.name
                    }}</span>
                    <span v-else> - </span>
                  </b-td> -->
                  <b-td v-if="role == 'SA'">
                    <button
                      :id="`popover-1-${laporan.id}`"
                      class="btn btn-invc p-0"
                    >
                      <div class="action-wrapper font-12">
                        <i class="ri-more-2-fill ri-lg ri-mid"></i>
                      </div>
                    </button>
                    <b-popover
                      :target="`popover-1-${laporan.id}`"
                      placement="right"
                      triggers="focus"
                    >
                      <div class="action-wrapper font-12">
                        <table>
                          <tr class="pointer" @click="onDetail(laporan)">
                            <td style="padding: 4px">
                              <i
                                class="ri-eye-fill ri-lg ri-sub action-btn"
                              ></i>
                            </td>
                            <td>{{ $t("global.detail") }}</td>
                          </tr>
                          <tr class="pointer" @click="onEdit(laporan)">
                            <td style="padding: 4px">
                              <i
                                class="ri-edit-fill ri-lg ri-sub action-btn"
                              ></i>
                            </td>
                            <td>{{ $t("global.edit") }}</td>
                          </tr>
                          <tr class="pointer" @click="onDelete(laporan.id)">
                            <td style="padding: 4px">
                              <i
                                class="ri-delete-back-2-fill ri-lg ri-sub action-btn"
                              ></i>
                            </td>
                            <td>{{ $t("global.delete") }}</td>
                          </tr>
                        </table>
                      </div>
                    </b-popover>
                  </b-td>
                  <b-td class="action-column" v-else>
                    <div class="action-wrapper font-12">
                      <i
                        class="ri-eye-fill ri-lg ri-mid action-btn"
                        @click="onDetail(laporan)"
                      ></i>
                    </div>
                  </b-td>
                </b-tr>
              </b-tbody>
              <b-tbody v-else class="text-center text-italic">
                <!-- <b-tbody class="text-center"> -->
                <b-tr>
                  <b-td colspan="10">{{ $t("global.empty") }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>

            <div class="row align-items-center table-footer">
              <div class="col-sm-6 ssp-14-700">
                <!-- 1 - 6 of {{ this.totalData }} -->
                {{ $t("global.total_data") }} {{ this.totalData }}
              </div>
              <div class="col-sm-6">
                <div class="row">
                  <div class="col-sm-7 align-self-center page-text">
                    {{ $t("global.page_on") }}
                  </div>
                  <div class="col float-right">
                    <div class="row">
                      <select
                        class="form-control pagination-pg"
                        v-model="currPage"
                      >
                        <option
                          v-for="index in totalPages"
                          :key="index.id"
                          :value="index"
                        >
                          {{ index++ }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col page-arrow ssp-14-700 ml-2">
                    <div class="row float-right">
                      <div
                        @click="redcPage"
                        class="btn table-button pagination-btn ml-auto d-flex align-items-center"
                      >
                        <div class="font-12" :class="{ orange: currPage > 1 }">
                          <i class="ri-arrow-left-line ri-lg ri-sub"></i>
                        </div>
                      </div>
                      <div
                        @click="addPage"
                        class="btn table-button pagination-btn ml-auto d-flex align-items-center"
                      >
                        <div
                          class="font-12"
                          :class="{ orange: currPage < totalPages }"
                        >
                          <i class="ri-arrow-right-line ri-lg ri-sub"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
    <b-modal id="modal-laporan" hide-footer>
      <template #modal-header="{ close }">
        <!-- Emulate built in modal header close button action -->
        <h5 class="ssp-24 text-black">
          <img
            class="mr-2"
            src="@/assets/img/tbhdata-form.png"
            alt="FORM"
            style="height: 1.8rem;"
          />
          {{ modalTitle }}
        </h5>
        <div class="pointer font-14" @click="close()">
          <i class="ri-close-circle-line ri-xl ri-mid"></i>
        </div>
      </template>

      <template #default="{ hide }">
        <!-- <p class="">Modal Body with button</p> -->
        <form @submit.prevent="onUpdate">
          <div class="form-group" v-if="isDetail">
            <label for="nama-produk" class="ssp-16 text-black">
              User
            </label>
            <input
              type="text"
              class="form-control"
              id="region-laporan"
              v-model="formTambah.from.name"
              :disabled="isDisabled"
            />
          </div>
          <div class="form-group" v-if="isDetail">
            <label for="nama-produk" class="ssp-16 text-black">
              {{ $t("liquidation.label.region") }}
            </label>
            <input
              type="text"
              class="form-control"
              id="region-laporan"
              v-model="formTambah.region"
              :disabled="isDisabled"
            />
          </div>
          <div class="form-group" v-if="isDetail">
            <label for="nama-produk" class="ssp-16 text-black">
              {{ $t("liquidation.label.date") }}
            </label>
            <input
              type="text"
              class="form-control"
              id="nama-produk"
              v-model="formTambah.tanggal"
              :disabled="isDisabled"
            />
          </div>
          <div class="form-group">
            <label for="nama-produk" class="ssp-16 text-black">
              {{ $t("liquidation.label.product") }}
            </label>
            <input
              v-if="isDetail"
              type="text"
              class="form-control"
              v-model="formTambah.produk.brand"
              :disabled="true"
            />
          </div>
          <div class="form-group">
            <label for="harga-retailer" class="ssp-16 text-black">
              {{ $t("liquidation.label.rp") }}
            </label>
            <vue-numeric
              id="harga-retailer"
              v-if="!isEdit"
              currency="Rp"
              separator="."
              class="form-control"
              v-model="formTambah.harga_retailer"
              disabled
            />
            <vue-numeric
              v-else
              id="harga-retailer"
              currency="Rp"
              separator="."
              class="form-control"
              v-model="formTambah.harga_retailer"
              :placeholder="$t('liq_reporting.placeholder.rp')"
              @input="setValueLikuidasi"
            />
            <!-- <input
              v-else
              type="text"
              class="form-control"
              id="harga-retailer"
              v-model="formTambah.harga_retailer"
              :placeholder="$t('liq_reporting.placeholder.rp')"
              @input="setValueLikuidasi"
            /> -->
          </div>
          <div class="form-group">
            <label for="qty-lkd" class="ssp-16 text-black">
              {{ $t("liquidation.label.qty") }}
            </label>
            <input
              v-if="!isEdit"
              type="text"
              class="form-control"
              id="qty-lkd"
              v-model="formTambah.qty"
              :placeholder="$t('liq_reporting.placeholder.qty')"
              :disabled="isDisabled"
            />
            <input
              v-else
              type="text"
              class="form-control"
              id="qty-lkd"
              v-model="formTambah.qty"
              :placeholder="$t('liq_reporting.placeholder.qty')"
              @input="setValueLikuidasi"
            />
          </div>
          <div class="form-group">
            <label for="val-lqd" class="ssp-16 text-black">
              {{ $t("liquidation.label.value") }}
            </label>
            <vue-numeric
              id="val-lqd"
              currency="Rp"
              separator="."
              class="form-control"
              v-model="formTambah.value_lik"
              disabled
            />
          </div>
          <div class="form-group" v-if="!isDetail">
            <label class="ssp-16 text-black">
              {{ $t("retailers.label.type") }}
            </label>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="retailer"
                id="retailer1"
                value="r1"
                v-model="retailerSelected"
                required
              />
              <label class="form-check-label" for="retailer1">
                Retailer 1
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="retailer"
                id="retailer2"
                value="r2"
                v-model="retailerSelected"
                required
              />
              <label class="form-check-label" for="retailer2">
                Retailer 2
              </label>
            </div>
          </div>
          <div
            class="form-group"
            v-if="
              retailerSelected === 'r2' ||
                (isDetail === true && formTambah.retailer2 !== null)
            "
          >
            <label class="ssp-16 text-black">
              {{ $t("liquidation.label.r2") }}
            </label>
            <input
              v-if="isDetail"
              type="text"
              class="form-control"
              v-model="formTambah.retailer2.name"
              :disabled="true"
            />
          </div>
          <div
            class="form-group"
            v-if="retailerSelected !== '' || isDetail === true"
          >
            <label class="ssp-16 text-black">
              {{ $t("liquidation.label.r1") }}
            </label>
            <input
              v-if="isDetail"
              type="text"
              class="form-control"
              v-model="formTambah.retailer1.name"
              :disabled="true"
            />
          </div>
          <div
            class="form-group"
            v-if="
              retailerSelected === 'r1' ||
                (isDetail === true && formTambah.distributor !== null)
            "
          >
            <label class="ssp-16 text-black">
              {{ $t("liquidation.label.dist") }}
            </label>
            <input
              v-if="isDetail"
              type="text"
              class="form-control"
              v-model="formTambah.distributor.name"
              :disabled="true"
            />
          </div>
          <div class="form-group">
            <label class="ssp-16 text-black">
              {{ $t("liquidation.label.inv") }}
            </label>
            <div class="col-md-10 offset-1 mt-3">
              <img
                v-if="formTambah.invoice != null"
                class="d-block ml-auto mr-auto"
                :src="url + formTambah.invoice"
                :alt="formTambah.invoice"
              />
              <div v-else class="mx-auto text-center">
                {{ $t("feedback.not_avb") }}
              </div>
            </div>
          </div>
          <button
            v-if="isEdit"
            type="submit"
            class="btn btn-primary ssp-14 w-100 my-3 py-2"
            :class="{ disabled: loading }"
          >
            <div v-if="loading" class="spinner mr-2 d-inline">
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            </div>
            <span class="d-inline">
              {{ $t("global.submit_btn") }}
            </span>
          </button>
        </form>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { Card } from "@/components/index";
import _ from "lodash";
import moment from "moment";
import { Q_LIST_REPORT, Q_LIST_REGION } from "@/graphql/mt";
import { M_DEL_REPORT, M_EDIT_REPORT } from "@/graphql/tss";
import { Q_LIST_PRODUK, Q_LIST_USERSEARCH } from "@/graphql/queries";
import {
  RegHolder,
  RetailerType,
  RoleType,
  filterDateType
} from "@/graphql/enum.type.js";
import JwtService from "@/core/services/jwt.service";
import VueNumeric from "vue-numeric";
import Axios from "axios";

export default {
  components: {
    Card,
    VueNumeric
  },
  data() {
    return {
      isLoading: false,
      dataLaporan: [],
      dataRegion: [],
      dataUser: [],
      filterRole: null,
      filterRoleApply: [],
      searchUser: "",
      url: JwtService.getURL(),
      role: JwtService.getRole(),

      roleOptions: [RoleType.RM, RoleType.AM, RoleType.TSS, RoleType.SP],
      filterDateTypes: [
        filterDateType.TODAY,
        filterDateType.YESTERDAY,
        filterDateType.THIS_WEEK,
        filterDateType.THIS_MONTH,
        filterDateType.CUSTOM_DATE
      ],

      loading: false,
      totalPages: null,
      currPage: 1,
      perPage: 10,
      totalData: null,
      filterInput: "",
      filterRegion: [],
      filterProduk: [],
      searchProduk: "",
      show_filter: false,
      customFilter: false,
      filterSelected1: "",
      filterSelected2: "",
      filterDateType: null,

      today: moment(new Date(new Date().setHours(0, 0, 0, 0))).format(
        "YYYY-MM-DD"
      ),
      yesterday: moment(
        new Date(new Date().setHours(0, 0, 0, 0) - 86400000)
      ).format("YYYY-MM-DD"),
      firstDayWeek: moment()
        .startOf("week")
        .toDate(),
      lastDayWeek: moment()
        .endOf("week")
        .toDate(),
      firstDayMonth: null,
      lastDayMonth: null,
      region_id: [],
      regions: [],

      lapIDs: [],
      selected: [],
      allSelected: false,

      dataProduk: [],
      dataR1: [],
      dataR2: [],
      dataDistributor: [],

      regHolderOptions: [RegHolder.PT_CAM, RegHolder.PT_UPLI],
      retailerTypeOptions: [RetailerType.R1, RetailerType.R2],
      modalTitle: "",
      isEdit: false,
      isDisabled: false,
      isDetail: false,
      retailerSelected: "",
      formTambah: {
        id: "",
        tanggal: "",
        produk: "",
        harga_retailer: null,
        qty: null,
        value_lik: null,
        retailer1: null,
        retailer2: null,
        distributor: null
      }
    };
  },
  apollo: {
    listLaporan: {
      query: () => Q_LIST_REPORT,
      variables() {
        return {
          page: this.currPage,
          limit: this.perPage,
          from: this.filterRoleApply,
          to: "",
          target_id: "",
          country_id: this.getCountryID(),
          region_id:
            this.filterRegion.length === 0
              ? this.getRegionID()
              : this.filterRegion,
          prov_id: [],
          kab_id: [],
          kec_id: [],
          kel_id: [],
          r1: [],
          r2: [],
          dis: [],
          petani_id: [],
          produk_id: this.filterProduk,
          year: "",
          month: "",
          keyword: this.filterInput,
          start: this.filterSelected1,
          end: this.filterSelected2,
          dateType: this.filterDateType
        };
      },
      debounce: 500,
      result({ data }) {
        this.dataLaporan = data.listLaporan.laporans;
        this.totalPages = data.listLaporan.totalPage;
        this.totalData = data.listLaporan.total;
      },
      error(err) {
        this.catchError(err);
      },
      watchLoading(isLoading) {
        this.loading = isLoading;
      }
    },
    listProduk: {
      query: () => Q_LIST_PRODUK,
      variables() {
        return {
          page: 1,
          limit: 99999,
          keyword: this.searchProduk
        };
      },
      result({ data }) {
        this.dataProduk = data.listProduk.produks;
      }
    },
    listUser: {
      query: () => Q_LIST_USERSEARCH,
      variables() {
        return {
          keyword: this.searchUser,
          role: this.filterRole,
          country_id: [],
          region_id: this.getRegionID(),
          prov_id: [],
          kab_id: [],
          kec_id: [],
          kel_id: [],
          tss_id: [],
          orderBy: "NAME",
          sortBy: "ASC"
        };
      },
      debounce: 500,
      // update: data => data.listUser,
      result({ data }) {
        this.dataUser = data.listUser.users;
      }
    },
    listRegion: {
      query: () => Q_LIST_REGION,
      result({ data }) {
        const r = this.getRegionID();
        if (r.length > 0) {
          this.dataRegion = data.listRegion.regions.filter(reg => {
            if (r.includes(parseInt(reg.id))) {
              return reg;
            }
          });
        } else {
          this.dataRegion = data.listRegion.regions;
        }
      }
    }
  },
  computed: {},
  methods: {
    getDay(value) {
      return moment(value).format("DD");
    },
    formatDate(value) {
      return moment(value).format("YYYY-MM-DD");
    },
    setFilterSelectedWeek() {
      this.filterSelected2 = this.lastDayWeek;
    },
    setFilterSelectedMonth() {
      this.filterSelected2 = this.lastDayMonth;
    },
    resetFilter() {
      this.filterSelected1 = "";
      this.filterSelected2 = "";
      this.filterDateType = null;
      this.customFilter = false;
      this.$apollo.queries.listLaporan.refetch();
      this.filterRegion = [];
      this.filterProduk = [];
      this.filterRoleApply = [];
      this.searchUser = "";
      var elements = document.getElementsByTagName("input");
      for (var i = 0; i < elements.length; i++) {
        if (elements[i].type == "radio") {
          elements[i].checked = false;
        }
      }
    },
    resetFilter1() {
      this.filterSelected2 = "";
    },
    selectAll() {
      this.lapIDs = [];
      if (!this.allSelected) {
        for (let i in this.dataLaporan) {
          this.lapIDs.push(this.dataLaporan[i].id);
        }
      }
    },
    select() {
      this.allSelected = false;
    },
    setValueLikuidasi() {
      this.formTambah.value_lik =
        this.formTambah.harga_retailer * this.formTambah.qty;
    },
    onDetail(data) {
      this.modalTitle = this.$t("liquidation.detail");
      this.isDetail = true;
      this.isEdit = false;
      this.isDisabled = true;
      this.resetForm();
      this.retailerSelected = "";
      this.formTambah.id = data.id;
      this.formTambah.from = data.froms;
      this.formTambah.tanggal = data.day_report;
      this.formTambah.produk = data.produknames;
      this.formTambah.harga_retailer = data.price;
      this.formTambah.qty = data.quantity;
      this.formTambah.value_lik = data.value;
      if (data.retailers2 !== null) {
        this.formTambah.retailer2 = data.retailers2;
      }
      this.formTambah.retailer1 = data.retailers1;
      if (data.distributors !== null) {
        this.formTambah.distributor = data.distributors;
      }
      this.formTambah.invoice = data.invoice;
      const region = _.uniq(data.froms.detail.map(a => a.regions.region));
      this.formTambah.region = region.join(", ");
      this.$bvModal.show("modal-laporan");
    },
    onEdit(data) {
      this.modalTitle = this.$t("liquidation.detail");
      this.isDetail = true;
      this.isEdit = true;
      this.isDisabled = true;
      this.resetForm();
      this.retailerSelected = "";
      this.formTambah.id = data.id;
      this.formTambah.from = data.froms;
      this.formTambah.tanggal = data.day_report;
      this.formTambah.produk = data.produknames;
      this.formTambah.harga_retailer = data.price;
      this.formTambah.qty = data.quantity;
      this.formTambah.value_lik = data.value;
      if (data.retailers2 !== null) {
        this.formTambah.retailer2 = data.retailers2;
      }
      this.formTambah.retailer1 = data.retailers1;
      if (data.distributors !== null) {
        this.formTambah.distributor = data.distributors;
      }
      this.formTambah.invoice = data.invoice;
      const region = _.uniq(data.froms.detail.map(a => a.regions.region));
      this.formTambah.region = region.join(", ");
      this.$bvModal.show("modal-laporan");
    },
    onDelete(id) {
      this.deleteConfirm().then(async result => {
        if (result.isConfirmed) {
          try {
            const response = await new Promise((resolve, reject) => {
              const result = this.$apollo.mutate({
                mutation: M_DEL_REPORT,
                variables: {
                  id: id
                }
              });
              if (resolve) {
                resolve(result);
              } else {
                reject(result);
              }
            });
            if (response) {
              this.toastAlert("Berhasil menghapus data!");
              this.$apollo.queries.listLaporan.refetch();
            }
          } catch (err) {
            this.errorAlert(err);
          }
        }
      });
    },
    async onUpdate() {
      this.loading = true;
      let payload = {};
      if (this.formTambah.distributor) {
        payload = {
          id: this.formTambah.id,
          from: this.formTambah.from.id,
          day_report: this.formTambah.tanggal,
          produk_id: this.formTambah.produk.id,
          price: parseInt(this.formTambah.harga_retailer),
          target_quantity: parseInt(this.formTambah.qty),
          target_value: this.formTambah.value_lik.toString(),
          r1: this.formTambah.retailer1.id,
          bulan: this.formTambah.bulan,
          tahun: this.formTambah.tahun,
          distributor: this.formTambah.distributor.id,
          invoice: this.formTambah.invoice
        };
      } else {
        payload = {
          id: this.formTambah.id,
          from: this.formTambah.from.id,
          day_report: this.formTambah.tanggal,
          produk_id: this.formTambah.produk.id,
          price: parseInt(this.formTambah.harga_retailer),
          target_quantity: parseInt(this.formTambah.qty),
          target_value: this.formTambah.value_lik.toString(),
          r1: this.formTambah.retailer1.id,
          r2: this.formTambah.retailer2.id,
          bulan: this.formTambah.bulan,
          tahun: this.formTambah.tahun,
          invoice: this.formTambah.invoice
        };
      }
      try {
        const response = await new Promise((resolve, reject) => {
          const res = this.$apollo.mutate({
            mutation: M_EDIT_REPORT,
            variables: payload
          });
          if (resolve) {
            resolve(res);
          } else {
            reject(res);
          }
        });
        this.$apollo.queries.listLaporan.refetch();
        if (response) {
          this.toastAlert(this.$t("alert.edit"));
          this.loading = false;
          this.closeModal();
        }
      } catch (err) {
        this.$swal.fire({
          icon: "error",
          title: "Error!",
          text: err.message,
          width: 400,
          confirmButtonText: "OK"
        });
        this.loading = false;
      }
    },
    closeModal() {
      this.$bvModal.hide("modal-laporan");
    },
    resetForm() {
      this.formTambah.id = "";
      this.formTambah.id = "";
      this.formTambah.tanggal = "";
      this.formTambah.produk = "";
      this.formTambah.harga_retailer = null;
      this.formTambah.qty = null;
      this.formTambah.value_lik = null;
      this.formTambah.retailer1 = null;
      this.formTambah.retailer2 = null;
      this.formTambah.distributor = null;
      this.formTambah.invoice = "";
    },
    setPage() {
      this.currPage = 1;
    },
    addPage() {
      if (this.currPage < this.totalPages) {
        this.currPage++;
      }
    },
    redcPage() {
      if (this.currPage > 1) {
        this.currPage--;
      }
    },
    async handleExport() {
      this.isLoading = true;
      await Axios.post(
        process.env.VUE_APP_URL_GRAPHQL + "/laporan/excel",
        {
          dateType: this.handleCustomDateExport(this.filterDateType),
          start_date: this.filterSelected1,
          end_date: this.filterSelected2,
          user: this.filterRoleApply,
          region: this.filterRegion,
          product: this.filterProduk
        },
        {
          responseType: "arraybuffer",
          headers: {
            Authorization: "Bearer " + JwtService.getToken(),
            "Content-Type": "application/json"
          }
        }
      )
        .then(response => {
          const type = response.headers["content-type"];
          const blob = new Blob([response.data], {
            type: type,
            encoding: "UTF-8"
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `likuidasi-${this.dateFr(
            new Date(),
            "DDMMYYYYHHMMss"
          )}.xlsx`;
          link.click();
        })
        .catch(e => {
          alert(e);
        });
      this.isLoading = false;
    }
  },
  mounted() {
    const dateNow = new Date(new Date().setHours(0, 0, 0, 0));
    this.firstDayMonth = new Date(dateNow.getFullYear(), dateNow.getMonth(), 1);
    this.lastDayMonth = new Date(
      dateNow.getFullYear(),
      dateNow.getMonth() + 1,
      0
    );
  },
  created() {
    this.filterRole = this.roleOptions[0];
  }
};
</script>

<style>
.form-check input {
  margin-left: 0;
}

.page-text {
  text-align: right;
}

.page-arrow {
  max-width: fit-content;
}

@media screen and (max-width: 1146px) {
  .filter-button {
    width: 140%;
    /* background-color: red; */
  }
}

@media screen and (max-width: 1111px) {
  .page-arrow {
    min-width: fit-content;
  }
}

@media screen and (max-width: 766px) {
  .export-button {
    margin-top: 0.5rem;
    width: 100% !important;
  }

  .filter-button {
    margin-top: 0.5rem;
    width: 100% !important;
  }
}

@media screen and (max-width: 566px) {
  .page-text {
    text-align: left !important;
  }
}
</style>
