import gql from "graphql-tag";
// PETANI
export const Q_LIST_FARMER = gql`
  query listPetani(
    $keyword: String
    $pages: Int
    $limit: Int
    $sp_id: [String!]
    $tss_id: [String!]
    $country_id: [Int!]
    $region_id: [Int!]
    $prov_id: [String!]
    $kab_id: [String!]
  ) {
    listPetani(
      listPetaniInput: {
        page: $pages
        limit: $limit
        search: {
          keyword: $keyword
          sp_id: $sp_id
          tss_id: $tss_id
          country_id: $country_id
          region_id: $region_id
          prov_id: $prov_id
          kab_id: $kab_id
        }
      }
    ) {
      page
      totalPage
      total
      petanis {
        id
        name
        sp {
          id
          name
        }
        tss {
          id
          name
        }
        kab_id
        kec {
          id
          kecamatan
        }
        kel {
          id
          kelurahan
        }
        luas
        alamat
        hp
        ucrops {
          id
          crop
        }
        ocrops {
          id
          crop
        }
      }
    }
  }
`;

// LAPORAN
export const Q_LIST_REPORT = gql`
  query listLaporan(
    $page: Int
    $limit: Int
    $keyword: String
    $from: [String!]
    $to: String
    $target_id: String
    $country_id: [Int!]
    $region_id: [Int!]
    $prov_id: [String!]
    $kab_id: [String!]
    $kec_id: [String!]
    $kel_id: [String!]
    $r1: [Int!]
    $r2: [Int!]
    $dis: [Int!]
    $petani_id: [String!]
    $produk_id: [String!]
    $year: String
    $month: String
    $start: DateTime
    $end: DateTime
    $dateType: filterDateType
  ) {
    listLaporan(
      listLaporanInput: {
        page: $page
        limit: $limit
        order: { sortBy: DESC, orderBy: CREATED_AT }
        search: {
          keyword: $keyword
          from: $from
          to: $to
          target_id: $target_id
          country_id: $country_id
          region_id: $region_id
          prov_id: $prov_id
          kab_id: $kab_id
          kec_id: $kec_id
          kel_id: $kel_id
          r1: $r1
          r2: $r2
          dis: $dis
          petani_id: $petani_id
          produk_id: $produk_id
          year: $year
          month: $month
          likR1: null
          likR2: null
          start_date: $start
          end_date: $end
          dateType: $dateType
        }
      }
    ) {
      page
      total
      totalPage
      laporans {
        id
        day_report
        froms {
          id
          name
          role
          detail {
            regions {
              id
              region
            }
          }
        }
        month
        produknames {
          id
          brand
        }
        price
        quantity
        value
        retailers1 {
          id
          name
          owner
        }
        retailers2 {
          id
          name
          owner
        }
        distributors {
          id
          name
          owner
        }
        invoice
      }
    }
  }
`;

// LAPORAN HARIAN
export const Q_LIST_DAILY_REPORT = gql`
  query listDailyReport(
    $pages: Int
    $limit: Int
    $keyword: String
    $region_id: [ID!]
    $user_id: [ID!]
    $role_types: roleType
    $daily_types: dailyActivityType
    $start_date: DateTime
    $end_date: DateTime
  ) {
    listDailyReport(
      listDailyReportInput: {
        page: $pages
        limit: $limit
        search: {
          keyword: $keyword
          region_id: $region_id
          user_id: $user_id
          role_types: $role_types
          daily_types: $daily_types
          start_date: $start_date
          end_date: $end_date
        }
      }
    ) {
      page
      totalPage
      dailys {
        users {
          id
          name
          phone
        }
        role
        regions {
          id
          region
        }
        tanggal
        kegiatan
        distributor1 {
          id
          name
        }
        hd1
        distributor2 {
          id
          name
        }
        hd2
        retailer1 {
          id
          name
        }
        hr1
        retailer2 {
          id
          name
        }
        hr2
        retailer3 {
          id
          name
        }
        hr3
        petani1 {
          id
          name
        }
        hp1
        petani2 {
          id
          name
        }
        hp2
        nama_kebun
        hasil_kebun
        nama_marketing
        hasil_marketing
        nama_administrasi
        hasil_administrasi
        dokumentasi
      }
    }
  }
`;

export const Q_LIST_REGION = gql`
  query listRegion {
    listRegion(
      listRegionInput: { page: 1, limit: 999, search: { keyword: "" } }
    ) {
      regions {
        id
        region
      }
    }
  }
`;

export const Q_LIST_TARGET = gql`
  query listTarget(
    $page: Int
    $limit: Int
    $keyword: String
    $types: SalesTargetType
    $from: String
    $to: String
    $tahun: String
    $bulan: String
    $country_id: [ID!]
    $region_id: [ID!]
    $provinsi: [String!]
    $kabupatens: [String!]
    $kecamatans: [String!]
    $kelurahans: [String!]
    $produk_id: [String!]
  ) {
    listTarget(
      listTargetInput: {
        page: $page
        limit: $limit
        search: {
          keyword: $keyword
          types: $types
          from: $from
          to: $to
          tahun: $tahun
          bulan: $bulan
          country_id: $country_id
          region_id: $region_id
          provinsi: $provinsi
          kabupatens: $kabupatens
          kecamatans: $kecamatans
          kelurahans: $kelurahans
          produk_id: $produk_id
        }
      }
    ) {
      targets {
        id
        froms {
          id
          name
        }
        tos {
          id
          name
        }
        year
        month
        countrys {
          id
          country
        }
        regions {
          id
          region
        }
        provs {
          id
          province
        }
        kabs {
          id
          kabupaten
        }
        kecamatans {
          id
          kecamatan
        }
        kelurahans {
          id
          kelurahan
        }
        retailers1 {
          id
          name
        }
        retailers2 {
          id
          name
        }
        produknames {
          id
          brand
        }
        price
        tipe_target
        target_quantity
        target_value
        isReported
        tipe_target
      }
    }
  }
`;
